
import { initializeApp } from 'firebase/app';
import * as React from 'react';
import { environment } from '../environment/Environment';

const app = initializeApp(environment.firebaseConfig);
type FirebaseProviderProps = {children: React.ReactNode}
const FireBaseStateContext = React.createContext(app);


const FirebaseProvider = ({children}: FirebaseProviderProps) => {
    return (<FireBaseStateContext.Provider value={app}>
            {children}
        </FireBaseStateContext.Provider>);
} 

const firebaseApp = () => {
    const context = React.useContext(FireBaseStateContext);
    if(context === undefined) {
        throw new Error('useLogin must be within a LoginProvider');
    }
    return context;
}

export {FirebaseProvider, firebaseApp}