export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCXIcOdWJ0i-TjkTOS-CjTn1vnqbQvKPRM",
    authDomain: "calificaciones-b5d6f.firebaseapp.com",
    databaseURL: "https://calificaciones-b5d6f.firebaseio.com",
    projectId: "calificaciones-b5d6f",
    storageBucket: "calificaciones-b5d6f.appspot.com",
    messagingSenderId: "631720088062",
    appId: "1:631720088062:web:cb25eeb74ec72f095870af",
  },
  studyApiUrlBase: 'https://study-api-service-production.up.railway.app/api/v1'
};