import * as React from 'react';
import { useState } from "react";
import {Appearance} from 'react-native';
import { Themes } from '../styles/Theme';
type CountProviderProps = {children: React.ReactNode}
let colorScheme = Appearance.getColorScheme();
if(colorScheme == null) {
    colorScheme = 'light';
}
const currentTheme = Themes[colorScheme];
const ThemeStateContext = React.createContext<{theme: any; setTheme: React.Dispatch<React.SetStateAction<any>>} | undefined>(undefined);


const ThemeProvider = ({children}: CountProviderProps) => {
    const [theme, setTheme] = useState(currentTheme);
    const value =  { theme, setTheme };
    return (<ThemeStateContext.Provider value={value}>
            {children}
        </ThemeStateContext.Provider>);
} 

const useTheme = () => {
    const context = React.useContext(ThemeStateContext);
    if(context === undefined) {
        throw new Error('useTheme must be within a ThemeProvider');
    }
    return context;
}

export {ThemeProvider, useTheme}