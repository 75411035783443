import React, { useEffect, useState } from "react";
import {DrawerActions, useNavigation } from '@react-navigation/native';
import { Pressable, useWindowDimensions } from "react-native";
import { useTheme } from "../context/ThemeContext";
import { FontAwesome } from '@expo/vector-icons'; 


const HeaderLeft = ({...headerProps}: any)=> {
    const { theme } = useTheme();
    const dimensions = useWindowDimensions();
    const [ isLargeScreen, setIsLargeScreen ] = useState<boolean>();


    useEffect(()=> {
    if(dimensions) {
        setIsLargeScreen(dimensions.width >= 768);
    }
    }, [dimensions]);

    const navigationRef = useNavigation();
    return isLargeScreen? (<></>) : (
    <Pressable onPress={(props)=> navigationRef.dispatch(DrawerActions.toggleDrawer())}>
      <FontAwesome {...headerProps} name="navicon" size={24} color={theme.colors.white} style={{ marginLeft: 10}} />
    </Pressable>
  )
  };

export default HeaderLeft;