
import * as React from 'react';
import { View, StyleSheet } from 'react-native'; 
import GradePanel from './GradePanel';

const SubjectGradesPanel = ({subject, ...props}: any)=> (
    <View style={styles.main} {...props}>
        <View style={styles.container}>
            <GradePanel 
                description='Parcial 1'
                grade={subject.firstGrade}>
            </GradePanel>
            <GradePanel 
                description='Parcial 2'
                grade={subject.secondGrade}>
            </GradePanel>
            <GradePanel 
                description='Parcial 3'
                grade={subject.thirdGrade}>
            </GradePanel>
            <GradePanel
                description='Final'
                grade={subject.averange}>
            </GradePanel>
        </View>
    </View>
);

const styles = StyleSheet.create({
    main : {
        width: '100%'
    },
    container: {
        flexDirection:'row', alignItems:'center'
    },
    grade: {
        flex: 1,
        width: '25%'
    }
});

export default SubjectGradesPanel;