import { StyleSheet } from "react-native";
import Button from "./Button";
import Modal from "./Modal";
import Paragraph from "./Paragraph";
import { useTheme } from "../context/ThemeContext";

const AlertDialog = ({ visible, children, onConfirm, onDismiss, ...props }: any) => {
    const { theme } = useTheme();
    
    const styles = StyleSheet.create({
        confirmButton: {
            backgroundColor: theme.colors.secondary
        }
    });

    return (
        <Modal 
            visible={visible} onDismiss={onDismiss} 
            {...props}>
            {children}
            <Button onPress={onConfirm} style={styles.confirmButton}>
                Aceptar
            </Button>
        </Modal>
    )
};

export default AlertDialog;