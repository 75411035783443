
import AsyncStorage from '@react-native-async-storage/async-storage';
import { injectable } from "inversify";
import "reflect-metadata";

@injectable()
export class LocalStorageService {

    async setItem(key: string, obj: any): Promise<void> {
        return AsyncStorage.setItem(key, JSON.stringify(obj));
    }
  
    async getItem(key: string): Promise<any> {
      const value = await AsyncStorage.getItem(key);
      if (value === null) {
        return null;
      }
      return JSON.parse(value);
    }
  
    async removeItem(key: string): Promise<void> {
        return AsyncStorage.removeItem(key);
    }
  }
  