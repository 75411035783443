import * as React from 'react';
import { useState } from "react";
type CountProviderProps = {children: React.ReactNode}
const LoadingStateContext = React.createContext<{loading: boolean; setLoading: React.Dispatch<React.SetStateAction<boolean>>} | undefined>(undefined);


const LoadingProvider = ({children}: CountProviderProps) => {
    const [loading, setLoading] = useState(false);
    const value =  {loading, setLoading};
    return (<LoadingStateContext.Provider value={value}>
            {children}
        </LoadingStateContext.Provider>);
} 

const useLoading = () => {
    const context = React.useContext(LoadingStateContext);
    if(context === undefined) {
        throw new Error('useLogin must be within a LoginProvider');
    }
    return context;
}

export {LoadingProvider, useLoading}