import React from 'react';
import { StyleSheet } from 'react-native';
import { Button as PaperButton } from 'react-native-paper';
import { useTheme } from '../context/ThemeContext';

 const Button = ({ mode, style, labelStyle, ...props }: any) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    button: {
      width: '100%',
      backgroundColor: theme.colors.primary,
      marginVertical: 10,
      paddingVertical: 2,
    },
    text: {
      fontWeight: 'bold',
      fontSize: 15,
      lineHeight: 26,
      color: theme.colors.white
    },
  });
  
  return (
    <PaperButton
      style={[
        styles.button,
        style,
      ]}
      labelStyle={[styles.text, labelStyle]}
      mode={mode}
      {...props}
    />
  )
};


export default Button;