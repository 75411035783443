import * as React from 'react';
import { useState } from "react";
type CountProviderProps = {children: React.ReactNode}
const UserLogedStateContext = React.createContext<{userLoged: boolean; setUserLoged: React.Dispatch<React.SetStateAction<boolean>>} | undefined>(undefined);


const UserLogedProvider = ({children}: CountProviderProps) => {
    const [userLoged, setUserLoged] = useState(false);
    const value =  {userLoged, setUserLoged};
    return (<UserLogedStateContext.Provider value={value}>
            {children}
        </UserLogedStateContext.Provider>);
} 

const useUserLoged = () => {
    const context = React.useContext(UserLogedStateContext);
    if(context === undefined) {
        throw new Error('useUserLoged must be within a LoginProvider');
    }
    return context;
}

export {UserLogedProvider, useUserLoged}