
import * as React from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { Card } from 'react-native-paper';

const SubjectGradesCard = ({name, leftTitle, children, ...props}: any)=> {

    
    const getCardWidth = (width: number) => {
        if(width < 1010) {
            return '100%';
        }
        if(width < 1357) {
            return '49.5%';
        }
        return '32.5%';
    }
    const dimensions = useWindowDimensions();

    return (
        <Card style={{...styles.item, width: getCardWidth(dimensions.width) }}>
            <Card.Title
            titleNumberOfLines={4}
            titleStyle={{fontSize: 16}}
            title={name}
            left={(props) =>leftTitle(props)}
            style={{height: 100}}
            />                           
            <Card.Content>
            {children}
        </Card.Content>
        </Card>
    )
};

const styles = StyleSheet.create({
    item: {
        height: 160
    },
})

export default SubjectGradesCard;