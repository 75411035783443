
import { Auth, getAuth, onAuthStateChanged, signInAnonymously, signOut, UserCredential } from "firebase/auth";
import { resolve } from "inversify-react";
import { UserAuth } from "../models/userAuth";
import { TokenService } from "./TokenService";
import { useLoading } from "../context/LoadingContext";
import { injectable, inject } from "inversify";

@injectable()
export class AuthService {
    
    private auth: Auth;

    private setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    @inject(TokenService) private tokenService: TokenService;
    
    constructor() {
        this.auth = getAuth();
        const {setLoading} = useLoading();
        this.setLoading = setLoading;
    }


    async signIn(schoolCode: string, curp: string, controlNumber: string): Promise<UserAuth> {
        this.setLoading(true);
        try {
            const userCredentials = await signInAnonymously(this.auth);
            if(userCredentials.user) {
                const idTokenResult = await userCredentials.user.getIdTokenResult();
                const userAuth: UserAuth = {
                    schoolCode,
                    controlNumber,
                    curp,
                    uid: userCredentials.user.uid,
                    authToken:  {
                        token: idTokenResult.token,
                        claims: idTokenResult.claims,
                    }
                };
                this.tokenService.setUserAuth(userAuth);
                this.setLoading(false);
                return userAuth;
            }
            throw new Error("User not exist");
        } catch(error) {
            throw error;
        }
    }
    async getCurrentUser() {
        return await this.auth.currentUser?.getIdToken()
    }
    async signOut(): Promise<void> {
        return signOut(this.auth).then(() => {
          this.tokenService.removeUserAuth();
        });
      }
    
} 


