import 'react-native-gesture-handler';
import { DIContext } from './src/context/DIContext';
import { FirebaseProvider } from './src/context/FirebaseContext';
import { LoadingProvider } from './src/context/LoadingContext';
import { ThemeProvider } from './src/context/ThemeContext';
import { UserLogedProvider } from './src/context/UserLogedContext';
import NavigationLayout from './src/layouts/NavigationLayout';

const App = () => (
    <DIContext>
        <FirebaseProvider>
            <ThemeProvider>
                <LoadingProvider>
                    <UserLogedProvider>
                        <NavigationLayout></NavigationLayout>
                    </UserLogedProvider>
                </LoadingProvider>
            </ThemeProvider>
        </FirebaseProvider>
    </DIContext>
);


export default App; 
