import * as React from 'react';
import { Modal as ModalComponent, Portal, Text, Button, Provider } from 'react-native-paper';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { useTheme } from '../context/ThemeContext';

const Modal = ({ children, visible, style, onDismiss, ...props }: any) => {
  const {width} = useWindowDimensions();
  const { theme } = useTheme();
    

  const getModalWidth = () => {
    if(width < 960) {
      return '95%';
    }
    return '25%';
  }


  const styles = StyleSheet.create({
    containerStyle: {
      backgroundColor: theme.colors.background, 
      padding: 20,
      width: getModalWidth(),
      alignSelf: 'center',
      borderRadius: 4
    }
  });

  return (
      <Portal>
        <ModalComponent visible={visible} onDismiss={onDismiss} 
          contentContainerStyle={[
            styles.containerStyle, 
            style]} 
            {...props}
          >
          {children}
        </ModalComponent>
      </Portal>
  );
};

export default Modal;