import fetchIntercept from 'fetch-intercept';
import { TokenService } from '../services/TokenService';
import { injectable, inject } from "inversify";
import { environment } from '../environment/Environment';
import { NavigationContainerRefWithCurrent } from '@react-navigation/native';

@injectable()
export class AuthInterceptor  {

    @inject(TokenService) private tokenService: TokenService;
    
    constructor() {
        //this.initInterceptor();
    }

    initInterceptor(navigationRef: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>) {      
        const tokenService = this.tokenService;
        fetchIntercept.register({
            request:  async function (request: any, config) {
            // Modify the url or config here
                let currentUrl = request;
                if(typeof request !== 'string') {
                    currentUrl = request.url;
                }
                if (currentUrl.startsWith(environment.studyApiUrlBase)) {
                    const authToken = await tokenService.getAuthToken();
                    if(authToken && authToken.token) {
                        config = {
                            headers: {
                                Authorization: `Bearer ${authToken.token}`
                            }
                        };
                    }
                    console.log(config);
                }
                return [request, config];
            },
            response: function (response) {
            // Modify the reponse object
            if(response.status === 401) {
                tokenService.removeUserAuth().then(()=> {
                    navigationRef.reset({
                        index: 0,
                        routes: [{ name: 'Login' }],
                    });
                });
            }
            return response;
            },
        });
    }
};