import React, { useEffect, useState } from "react";
import { StyleSheet } from 'react-native';
import GradesScreen from "../features/grades/screens/GradesScreen";
import { useTheme } from "../context/ThemeContext";
import {useColorScheme} from 'react-native';
import { Themes } from "../styles/Theme";
import { useWindowDimensions } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { Feather, FontAwesome5 } from '@expo/vector-icons'; 
import NavDrawerStructure from "../components/NavDrawerStructure";
import HeaderLeft from "./HeaderLeft";
import { useInjection } from "inversify-react";
import { TokenService } from "../services/TokenService";
import { Student } from "../models/Student";
import { StudentService } from "../services/StudetService";
import { UserAuth } from "../models/UserAuth";
import AttendancesScreen from "../features/attendances/screens/AttendancesScreen";


const Drawer = createDrawerNavigator();


const AppStackNavigator = ({logoutAction}: any )=> {
  const colorScheme = useColorScheme();
  const dimensions = useWindowDimensions();
  const { theme, setTheme } = useTheme();
  const [ isLargeScreen, setIsLargeScreen ] = useState<boolean>();
  const tokenService = useInjection(TokenService);
  const [student, setStudent] = useState<Student | null>(null);
  const [studentFullName, setStudentFullName] = useState<string>('');
  const [userAuth, setUserAuth] = useState<UserAuth | null>(null);
  const studentService = useInjection(StudentService);

  useEffect(()=> {
    if(colorScheme) {
      const newTheme = Themes[colorScheme];
      setTheme(newTheme);
    }
  }, [colorScheme]);

  useEffect(()=> {
    if(dimensions) {
        setIsLargeScreen(dimensions.width >= 768);
    }
  }, [dimensions]);

  useEffect(()=> {
    loadUserAuth();
  }, []);

  useEffect(()=> {
    loadStudentData();
  }, [userAuth]);


const loadUserAuth = async(): Promise<void> =>{
  try {
    const userAuth = await tokenService.getUserAuth();
    setUserAuth(userAuth);
  }catch(e) {
      console.log(e);
  }
}

const loadStudentData = async (): Promise<void> => {
    //setLoading(true);
    try {
        if(userAuth) {
            const studentAuth: Student | null = await studentService.getStudent(userAuth.schoolCode, userAuth.curp, userAuth.controlNumber);
            if(studentAuth) {
                setStudent(studentAuth);
                const fullName = [studentAuth.name, studentAuth.lastName, studentAuth.motherLastName]
                 .reduce((prevValue, currentVal)=>{
                  if(currentVal) {
                    let nameFormated = currentVal.toString().trim();
                    prevValue = `${prevValue} ${nameFormated}`;
                  }
                  return prevValue.trim();
                 }, '');
                 setStudentFullName(fullName);
            }
        } else {
          setStudent(null);
        }
    }catch(e) {
        console.log(e);
    }
    //setLoading(false);
}


  const styles = StyleSheet.create({
    header:{
      backgroundColor: theme.colors.primary,
    },
    headerTtitle: {
      color: theme.colors.textSecondary
    },
    headerAlignment: {
      alignSelf: 'center'
    },
    screenItems: {
      color: theme.colors.white
    }
  });
  return (
        <Drawer.Navigator defaultStatus="closed"
         drawerContent={props => <NavDrawerStructure {...{name: studentFullName, curp: student?.curp, logoutAction: logoutAction, ...props}}/>}   
         screenOptions={{
            headerTitleStyle: styles.headerTtitle, 
            headerStyle: styles.header,
            headerTitleAlign: styles.headerAlignment.alignSelf, 
            //headerRight: headerRightComponent,
            drawerType: isLargeScreen ? 'permanent' : 'back',
            headerLeft: (props) => <HeaderLeft {...props} />,
            drawerActiveBackgroundColor: theme.colors.primary
            
           }}>
          <Drawer.Screen 
            name="Calificaciones"
            component={GradesScreen}         
            options={{
                drawerLabelStyle: {
                  color: styles.screenItems.color
                },
                drawerIcon: ({color}) => (
                    <FontAwesome5 name="graduation-cap" size={24} color={styles.screenItems.color} />
                ),
                }}
            />
            <Drawer.Screen 
              name="Asistencias" 
              component={AttendancesScreen}         
              options={{
                  drawerLabelStyle: {
                    color: styles.screenItems.color
                  },
                  drawerIcon: ({color}) => (
                      <Feather name="book-open" size={24} color={styles.screenItems.color} />
                  ),
                  }}
              />
        </Drawer.Navigator>
        )
};

export default AppStackNavigator;