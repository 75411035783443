
import * as FileSystem from 'expo-file-system';
import { useLoading } from "../context/LoadingContext";
import { injectable, inject } from "inversify";
import { collection, doc, Firestore, getDoc, getFirestore } from "firebase/firestore";
import { Student } from "../models/Student";
import { Grade } from "../models/Grade";
import { environment } from "../environment/Environment";
import { Platform } from 'react-native';
import { Attendance } from '../models/Attendance';
import { InplaceQuickSort } from '../helpers/OrderUtils';


@injectable()
export class StudentService {
    
    private db: Firestore;

    private apiStudyBaseService = `${environment.studyApiUrlBase}/students`;
    
    constructor() {
        this.db = getFirestore();
    }


    async getStudent(campusId: string, curp: string, controlNumber: string): Promise<Student | null> {
        const studentCode = this.getStudentCode(curp, controlNumber);
        const docRef = doc(this.db, `campuses/${campusId}/students`, studentCode).withConverter(this.getDataConverter());
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data();
        }
        return null;
    }

    getStudentCode(curp: string, controlNumber: string): string {
        return `${controlNumber}-${curp}`;
    }

    getDataConverter() {
        return {
            toFirestore: (student: Student) => {
                let grades: {[code:string]: any} | null = null;                
                let attendances: {[code:string]: any} | null = null;
                if(student.grades) {
                    grades = []
                    for(const grade of student.grades) {
                        const gradeCode = grade.course.toLowerCase();
                        grades[gradeCode] = {
                            course: grade.course,
                            firstPartialGrade: grade.firstPartialGrade,
                            secondPartialGrade: grade.secondPartialGrade,
                            thirdPartialGrade: grade.thirdPartialGrade,
                            grade: grade.grade,

                        };
                    }
                }
                if(student.attendances) {
                    attendances = []; 
                    for(const attendance of student.attendances) {
                        const attendanceCode = attendance.course.toLowerCase();
                        attendances[attendanceCode] = {
                            course: attendance.course,
                            firstPartialAttendance: attendance.firstPartialAttendance,
                            secondPartialAttendance: attendance.secondPartialAttendance,
                            thirdPartialAttendance: attendance.thirdPartialAttendance,
                            totalAttendance: attendance.totalAttendance,
                            maxAttendance: attendance.maxAttendance,
                        };
                    }
                }
                return {
                    name: student.name,
                    controlNumber: student.controlNumber,
                    curp: student.curp,
                    grades: grades,
                    attendances: attendances,
                    id: student.id,
                    group: student.group,
                    createdAt: student.createdAt,
                    lastName: student.lastName,
                    motherLastName: student.motherLastName,
                    semester: student.semester,
                    shift: student.shift,
                    updatedAt: student.updatedAt,

                };
            },
            fromFirestore: (snapshot: any, options: any) => {
                const data = snapshot.data(options);
                let grades: Array<Grade> | null = null;
                if(data.grades) {
                    grades = [];
                    let gradeData: any = null;
                    for(gradeData of Object.values(data.grades)) {
                        const grade: Grade = {
                            course: gradeData.course,
                            firstPartialGrade: gradeData.firstPartialGrade,
                            secondPartialGrade: gradeData.secondPartialGrade,
                            thirdPartialGrade: gradeData.thirdPartialGrade,
                            grade: gradeData.grade,
                        };
                        grades.push(grade);
                    }
                    InplaceQuickSort.sort<Grade>(grades, (a: Grade, b: Grade)=> a.course.localeCompare(b.course));
                } 
                let attendances: Array<Attendance> | null = null;
                if(data.attendances) {
                    attendances = [];
                    let attendanceData: any = null;
                    for(attendanceData of Object.values(data.attendances)) {
                        const attendance: Attendance = {
                            course: attendanceData.course,
                            firstPartialAttendance: attendanceData.firstPartialAttendance,
                            secondPartialAttendance: attendanceData.secondPartialAttendance,
                            thirdPartialAttendance: attendanceData.thirdPartialAttendance,
                            totalAttendance: attendanceData.totalAttendance,
                            maxAttendance: attendanceData.maxAttendance
                        };
                        attendances.push(attendance);
                    }                    
                    InplaceQuickSort.sort<Attendance>(attendances, (a: Attendance, b: Attendance)=> a.course.localeCompare(b.course));
                } 
                const student: Student = {
                    controlNumber: data.controlNumber,
                    curp: data.curp,
                    createdAt: data.createdAt,
                    grades: grades,
                    attendances: attendances,
                    group: data.group,
                    lastName: data.lastName,
                    motherLastName: data.motherLastName,
                    name: data.name,
                    semester: data.semester,
                    shift: data.shift,
                    updatedAt: data.updatedAt,
                    id: data.id
                };
                
                return student;
            }
        };
    }


    async getReportGradesDownload(campusId: string, controlNumber: string) {
        const queryParams = new URLSearchParams({
            campusId,
            controlNumber
        });
        const response = await fetch(`${this.apiStudyBaseService}/mobile/report-grades?` + queryParams);
        return await response.json();
    }

    downloadFile(filePath: string, controlNumber: string) {
        const FILE_URL = `${this.apiStudyBaseService}${filePath}`;  
        const extension = '.pdf';
        if(Platform.OS === 'web') {
            fetch(FILE_URL)
            .then( res => res.blob() )
            .then( blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = `boleta-de-calificaciones-${controlNumber}.pdf`;
                document.body.appendChild(a); 
                a.click();    
                a.remove();
            });
        } else {
            FileSystem.downloadAsync(
                FILE_URL,
                FileSystem.documentDirectory + controlNumber + extension
              )
                .then(({ uri }) => {
                  console.log('Finished downloading to ', uri);
                })
                .catch(error => {
                  console.error(error);
                });

        }
        /*
        // Get today's date to add the time suffix in filename
        const date = new Date();
        // File URL which we want to download  
        // Function to get extention of the file url
        const file_ext = '.pdf';
    
       fetch(FILE_URL)
        .then(res => {
            // Alert after successful downloading
            console.log('res -> ', JSON.stringify(res));
            alert('File Downloaded Successfully.');
        });*/
    }
    
} 