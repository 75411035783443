import React from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { TextInput as Input } from 'react-native-paper'
import { useTheme } from '../context/ThemeContext';

const TextInput = ({ errorText, description, ...props }: any) => {

  const { theme } = useTheme();

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      marginVertical: 12,
    },
    input: {
      backgroundColor: theme.colors.background,
    },
    description: {
      fontSize: 13,
      color: theme.colors.secondary,
      paddingTop: 8,
    },
    error: {
      fontSize: 13,
      color: theme.colors.error,
      paddingTop: 8,
    },
  });
  return (
    <View style={styles.container}>
      <Input
        style={styles.input}
        selectionColor={theme.colors.gray}
        textColor={theme.colors.text}
        underlineColor={theme.colors.lightgray}
        activeUnderlineColor={theme.colors.secondary}
        mode="outlined"
        {...props}
      />
      {description && !errorText ? (
        <Text style={styles.description}>{description}</Text>
      ) : null}
      {errorText ? <Text style={styles.error}>{errorText}</Text> : null}
    </View>
  )
}


export default TextInput