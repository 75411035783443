import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
} from 'react-native';
import {
  DrawerContentScrollView,
  DrawerItemList,
} from '@react-navigation/drawer';
import Ionicons from '@expo/vector-icons/Ionicons';
import { FontAwesome5 } from '@expo/vector-icons'; 
import { useTheme } from '../context/ThemeContext';
import Paragraph from './Paragraph';

const marginLeft = 10;

const NavDrawerStructure = ({name, curp, logoutAction, ...props}: any) => {
  const { theme } = useTheme();
  return (
    <View style={{flex: 1, backgroundColor: theme.colors.sideMenuBackground}}>
      <DrawerContentScrollView
        {...props}
        contentContainerStyle={{backgroundColor: theme.colors.sideMenuBackground}}>
        <View
          style={{
            flex: 1,
            flexDirection:'row', 
            alignItems:'center', 
            flexWrap: "wrap", 
            justifyContent: 'center',
            alignContent: 'center',
            columnGap: 5, 
            rowGap: 5,
            padding: 20, backgroundColor: theme.colors.sideMenuBackground}}>
          <FontAwesome5 name="user-circle"size={24} style={{color: theme.colors.white}} />
          <View style={{
            flex:1,
            alignItems: 'flex-start',
            justifyContent: 'center',
            alignContent: 'center',
            marginLeft: marginLeft
          }}>
            <Paragraph style={{marginBottom: 0, fontSize: 12, color: theme.colors.white}}>{name}</Paragraph>
            <Paragraph style={{marginBottom: 0, fontSize: 12, color: theme.colors.white}}>{curp}</Paragraph>
          </View>
        </View>
        <View style={{flex: 1, paddingTop: 10}}>
          <DrawerItemList {...props} />
        </View>
      </DrawerContentScrollView>
      <View style={{padding: 20, borderTopWidth: 1, borderColor: theme.colors.white}}>
        <TouchableOpacity onPress={() => {
          if(logoutAction) {
            logoutAction();
          }
        }} style={{paddingVertical: 15, flexDirection: 'row', alignItems: 'center', alignContent: 'center'}}>
          <View style={{flex:1, flexDirection: 'row', alignItems: 'center', alignContent: 'center'}}>
            <Ionicons name="exit-outline" size={22} style={{color: theme.colors.white}}  />
            <Paragraph style={{marginBottom: 0, fontSize: 12, marginLeft: marginLeft, color: theme.colors.white}}>
              Cerrar sesión
            </Paragraph>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};


  export default NavDrawerStructure;