
import * as React from 'react';
import { View, StyleSheet, useWindowDimensions } from 'react-native'; 
import { Text, Card, Button } from 'react-native-paper';
import Paragraph from '../../../components/Paragraph';
import { verticalScale } from '../../../helpers/Metrics';
import { useEffect, useState } from 'react';

const GradePanel = ({description, grade, style, textStyle, ...props}: any)=> {
    const {width} = useWindowDimensions();
    const [styles, setStyles] = useState(defautlStyles);


    const getStyleTitle = () => {
        return {
            ...styles.titlePanel,
            titlePanel: {
                minHeight: width < 960 ? verticalScale(1) : styles.titlePanel.minHeight 
            }
        }
    }
    

    useEffect(()=>{
        const titleStyle = getStyleTitle();
        const newStyles = {
            ...styles,
            ...titleStyle
        };
        setStyles(newStyles);
    },
    [width]);

    return (
        <View style={[styles.container, style]} {...props}>
            <View style={styles.titlePanel}>
                <Paragraph style={[textStyle, styles.descriptionP]}>{description}</Paragraph>
            </View>
            <View style={styles.gradePanel}>
                <Paragraph style={[styles.gradeP, textStyle]}>{grade ? grade: 'Pendiente'}</Paragraph>
            </View>
        </View>
    )
};

const defautlStyles = StyleSheet.create({
    container: {
        flex: 1,
        width: '20%',
    },
    descriptionP:{ 
        fontWeight: 'bold',
        textAlign: 'center'
    },
    gradePanel: {
        marginTop: 10
    },
    titlePanel: {
        minHeight: verticalScale(10)
    },
    gradeP: {
        textAlign: 'center'
    }
});

export default GradePanel;