
import { useInjection } from 'inversify-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { View, useWindowDimensions } from 'react-native';
import Background from '../../../components/Background';
import { Student } from '../../../models/Student';
import { StudentService } from '../../../services/StudetService';
import { TokenService } from '../../../services/TokenService';
import SubjectAttendancesCard from '../components/SubjectAttendacesCard';
import Subject from '../models/Subject';
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import { useLoading } from '../../../context/LoadingContext';
import gradesScreenStyle from '../styles/GradesScreenStyle';
import { useTheme } from '../../../context/ThemeContext';
import SubjectAttendancesPanel from '../components/SubjectAttendancesPanel';



const AttendancesScreen = ({navigation}: any)=> {
    const [subjects, setSubjects] = useState<JSX.Element[]>([]);
    const { setLoading } = useLoading();
    const [student, setStudent] = useState<Student | null>(null);
    const studentService = useInjection(StudentService);
    const tokenService = useInjection(TokenService);
    const {width} = useWindowDimensions();
    const { theme } = useTheme();
    const styles = gradesScreenStyle(width, theme);

    useEffect(()=> {
        loadData();
    }, []);

    const loadData = async (): Promise<void> => {
        setLoading(true);
        try {
            const userAuth = await tokenService.getUserAuth();
            if(userAuth) {
                const studentAuth: Student | null = await studentService.getStudent(userAuth.schoolCode, userAuth.curp, userAuth.controlNumber);
                if(studentAuth) {
                    setStudent(studentAuth);
                    //navigation.setOptions({title: studentAuth.name});
                    if(studentAuth.attendances) {
                        const studentSubjects = studentAuth.attendances
                        .map((attendance)=> {
                            const currentSubject: Subject = {
                                maxAttendance: attendance.maxAttendance,
                                totalAttendance: attendance.totalAttendance,
                                name: attendance.course,
                                firstPartialAttendance: attendance.firstPartialAttendance,
                                secondPartialAttendance: attendance.secondPartialAttendance,
                                thirdPartialAttendance: attendance.thirdPartialAttendance
                            };
                            return <SubjectAttendancesCard key={currentSubject.name} 
                                name={currentSubject.name}
                                leftTitle={(props:any) => (<MaterialCommunityIcons {...props} name="format-list-checks" size={24} color={theme.colors.secondary} />)}>
                                <SubjectAttendancesPanel subject={currentSubject} />
                            </SubjectAttendancesCard>;
                        });
                        setSubjects(studentSubjects);
                    }
                }
            }
        }catch(e) {
            console.log(e);
        }
        setLoading(false);
    }
    return (
        <Background>    
            <View style={ {...styles.background} }>                
                {subjects && 
                <View style={ styles.subjectContainer }>
                    {subjects}
                </View>}
            </View>
        </Background>
    )
};



export default AttendancesScreen;