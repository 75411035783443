import { UserAuth } from "../models/UserAuth";;
import { LocalStorageService } from "./LocalStorageService";
import { AuthToken } from "../models/AuthToken";
import { injectable, inject } from "inversify";

@injectable()
export class TokenService {
    static readonly USER_AUTH_KEY = 'APP_STUDENTS_USER_AUTH';
    @inject(LocalStorageService) private localStorageService: LocalStorageService
  
  
    async setUserAuth(userAuth: UserAuth): Promise<void> {
        return await this.localStorageService.setItem(TokenService.USER_AUTH_KEY, userAuth);
    }
  
    async getUserAuth(): Promise<UserAuth | null> {
      const userAuthModel = await this.localStorageService.getItem(
        TokenService.USER_AUTH_KEY
      );
      if (userAuthModel === null) {
        return null;
      }
      return userAuthModel as UserAuth;
    }
  
    async getAuthToken(): Promise<AuthToken | undefined> {
      const user = await this.getUserAuth();
      if(user === null) {
        return undefined;
      }
      return user.authToken;
    }
  
    async getUserClaims(): Promise<any | undefined> {
      const userAuthToken = await this.getAuthToken();
      if(userAuthToken === undefined) {
        return undefined;
      }
      return userAuthToken.claims;
    }
  
  
    async isAuthenticated(): Promise<boolean> {
      const userAuthModel = await this.localStorageService.getItem(
        TokenService.USER_AUTH_KEY
      );
      if (userAuthModel !== null) {
        return true;
      }
      return false;
    }
  
    async removeUserAuth(): Promise<void> {
      return await this.localStorageService.removeItem(TokenService.USER_AUTH_KEY);
    }
  }
  