
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import Spinner from 'react-native-loading-spinner-overlay';
import { useLoading } from '../context/LoadingContext';

const Loading = ({ props }: any) => {
    const { loading } = useLoading();
  return (
    <Spinner
        //visibility of Overlay Loading Spinner
        visible={loading}
        //Text with the Spinner
        textContent={'Loading...'}
        //Text style of the Spinner Text
        textStyle={styles.spinnerTextStyle} {...props}
    />
  )
};

const styles = StyleSheet.create({
    spinnerTextStyle: {
      color: '#FFF'
    },
  });


  export default Loading;