
import { useInjection } from 'inversify-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Text, View, TouchableHighlight, useWindowDimensions } from 'react-native';
import Background from '../../../components/Background';
import { Student } from '../../../models/Student';
import { FileService } from '../../../services/FileService';
import { StudentService } from '../../../services/StudetService';
import { TokenService } from '../../../services/TokenService';
import Subject from '../models/Subject';
import { FontAwesome5 } from '@expo/vector-icons'; 
import { useLoading } from '../../../context/LoadingContext';
import gradesScreenStyle from '../styles/GradesScreenStyle';
import { useTheme } from '../../../context/ThemeContext';
import SubjectGradesPanel from '../components/SubjectGradesPanel';
import SubjectGradesCard from '../components/SubjectGradesCard';



const GradesScreen = ({navigation}: any)=> {
    const [subjects, setSubjects] = useState<JSX.Element[]>([]);
    const { setLoading } = useLoading();
    const [reportGradeUrl, setReportGradeUrl] = useState<string | null>(null);
    const [student, setStudent] = useState<Student | null>(null);
    const studentService = useInjection(StudentService);
    const tokenService = useInjection(TokenService);
    const fileService = useInjection(FileService);
    const {width} = useWindowDimensions();
    const { theme } = useTheme();
    const styles = gradesScreenStyle(width, theme);

    useEffect(()=> {
        loadData();
    }, []);


    const donwloadReportGrades = (filePath: string, controlNumber: number | null | undefined)=> {
        if(controlNumber) {
            fileService.checkPermission(()=> {
                studentService.downloadFile(filePath, controlNumber.toString());
            });
        }
    };

    const loadData = async (): Promise<void> => {
        setLoading(true);
        try {
            const userAuth = await tokenService.getUserAuth();
            if(userAuth) {
                const reportGrade =  { url: null };//await studentService.getReportGradesDownload(userAuth.schoolCode, userAuth.controlNumber);
                if(reportGrade && reportGrade.url) {
                    setReportGradeUrl(reportGrade.url);
                } 
                const studentAuth: Student | null = await studentService.getStudent(userAuth.schoolCode, userAuth.curp, userAuth.controlNumber);
                if(studentAuth) {
                    setStudent(studentAuth);
                    //navigation.setOptions({title: studentAuth.name});
                    if(studentAuth.grades) {
                        const studentSubjects = studentAuth.grades
                        .map((grade)=> {
                            const currentSubject: Subject = {
                                averange: grade.grade,
                                name: grade.course,
                                firstGrade: grade.firstPartialGrade,
                                secondGrade: grade.secondPartialGrade,
                                thirdGrade: grade.thirdPartialGrade
                            };
                            return <SubjectGradesCard key={currentSubject.name} 
                                name={currentSubject.name}
                                leftTitle={(props:any) => (<FontAwesome5 {...props} name="book" size={24} color={theme.colors.secondary} />)}>
                                <SubjectGradesPanel subject={currentSubject} />
                            </SubjectGradesCard>;
                        });
                        setSubjects(studentSubjects);
                    }
                }
            }
        }catch(e) {
            console.log(e);
        }
        setLoading(false);
    }
    return (
        <Background>    
            <View style={ {...styles.background} }>                
                {subjects && 
                <View style={ styles.subjectContainer }>
                    {subjects}
                </View>}
            </View>
            { false && reportGradeUrl &&
            <View style={styles.downloadReportGradeContainer}>
                <TouchableHighlight key='reportGradeUrl' style={styles.downloadPanel} onPress={() => {
                    donwloadReportGrades(reportGradeUrl, student?.controlNumber);
                }}>
                    <View style={styles.buttonReportGrade}>
                        <FontAwesome5 name="file-download" size={24} color={theme.colors.secondary} />
                        <Text style={styles.textButtonReportGrade}>Descargar calificaciones</Text>
                    </View>
                </TouchableHighlight>
            </View>
            }
        </Background>
    )
};



export default GradesScreen;