import React from 'react'
import { StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'
import { useTheme } from '../context/ThemeContext';


const Paragraph = (props: any) => {
  const { theme } = useTheme();
    
const styles = StyleSheet.create({
  text: {
    fontSize: 15,
    lineHeight: 21,
    textAlign: 'center',
    marginBottom: 12,
    color: theme.colors.text
  },
});
  return <Text style={styles.text} {...props} />
}


export default Paragraph;