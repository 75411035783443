import { DefaultTheme } from 'react-native-paper'

export const LightTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    text: '#000000',
    textSecondary: '#FFFFFF',
    primary: '#f44236',
    secondary: '#438afe',
    error: '#f13a59',
    white: '#FFFFFF',
    background:'#FFFFFF',
    gray: '#969696',
    lightgray: '#d3d3d3',
    sideMenuBackground: '#8B0000'
  },
}