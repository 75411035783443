import React, { useEffect, useState } from "react";
import {NavigationContainer, useNavigationContainerRef} from '@react-navigation/native';
import { useLoading } from "../context/LoadingContext";
import { useInjection } from "inversify-react";
import { TokenService } from "../services/TokenService";
import { AuthInterceptor } from "../interceptors/AuthInterceptor";
import Paragraph from "../components/Paragraph";
import { Provider } from 'react-native-paper';
import ConfirmDialog from "../components/ConfirmDialog";
import HeaderRight from "../components/HeaderRight";
import { useUserLoged } from "../context/UserLogedContext";
import AppStackNavigator from "./AppStackNavigator";
import AuthStackNavigator from "./AuthStackNavigator";
import { useTheme } from "../context/ThemeContext";


const NavigationLayout = ()=> {
  const [ logoutVisible, setLogoutVisible ] = useState(false);
  const { setLoading} = useLoading(); 
  const { userLoged, setUserLoged }  = useUserLoged();
  const navigationRef = useNavigationContainerRef();
  const tokenService = useInjection(TokenService);
  const authInterceptor = useInjection(AuthInterceptor);
  const {theme} = useTheme();

  const onPressLogout = () => {
    setLogoutVisible(true);
  }

  
  const cancelLogout = () => {
    setLogoutVisible(false);
  }

  const confirmLogout = async () => {
    setLoading(true);
    try {
      await tokenService.removeUserAuth();
      setLogoutVisible(false);
      setUserLoged(false);
      checkToken();
    } catch(e) {
      console.log(e);
    }
    setLoading(false);
  }

  const checkToken = async () => {
    setLoading(true);
    const userAuthenticated = await tokenService.isAuthenticated();
    setUserLoged(userAuthenticated);
    setLoading(false);
  };

  const headerRightComponent = () => {
    return (
      <HeaderRight onPressLogout={onPressLogout}></HeaderRight>
    );
  }
  useEffect(()=> {
    authInterceptor.initInterceptor(navigationRef);
    checkToken();
  }, []);

  return (
    <Provider>
      <NavigationContainer theme={theme} ref={navigationRef}>
        { userLoged ? ( <AppStackNavigator logoutAction={onPressLogout} />) : 
          (<AuthStackNavigator />) }
        <ConfirmDialog visible={logoutVisible} onConfirm={confirmLogout} onDismiss={cancelLogout} onCancel={cancelLogout}>
          <Paragraph>¿Estas seguro que quieres salir de la sesion?</Paragraph>
        </ConfirmDialog>
      </NavigationContainer>
    </Provider>
  )
};

export default NavigationLayout;