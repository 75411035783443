
import { StyleSheet } from 'react-native';
import { verticalScale } from '../../../helpers/Metrics';

const getFormWidth = (width: number) => {
  if(width < 960) {
    return '100%';
  }
  return '25%';
}
const loginScreenStyle = (width: number) => StyleSheet.create({
  container:{
    flex: 1,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    height: verticalScale(70),
    width: getFormWidth(width) 
  },
  imageContainer: {
    height: verticalScale(150),
  },
  image: {
      flex: 1,
      aspectRatio: 1 / 2,
      resizeMode: 'contain'
  },
});
  
export default loginScreenStyle;