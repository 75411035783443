
import React, { useState } from 'react';
import { ScrollView, View, StyleSheet, KeyboardAvoidingView, Platform } from 'react-native';
import Loading from './Loading';
import { Paragraph, Provider } from 'react-native-paper';
import { useTheme } from '../context/ThemeContext';

const Background = ({ children, containerStyles, style }: any) => {
  const { theme } = useTheme()

  const styles = StyleSheet.create({
    background: {
        flex: 1,
        width: '100%',
        backgroundColor: theme.colors.background,
        marginVertical: 10 
    },
    scrollView: {
      maxHeight: '90%'
    },
    container: {
        flexGrow: 1,
        width: '100%',
        maxWidth: Platform.OS == 'ios' || Platform.OS == 'android' ? '100%' : '95%',    
        alignSelf: 'center',
        alignItems: 'center',
        backgroundColor: theme.colors.background
    }, 
    spinnerTextStyle: {
      color: theme.colors.white
    },
  });
  
  return (
    <ScrollView contentContainerStyle={[styles.background, style] }>
       <Loading></Loading>
        <KeyboardAvoidingView 
          style={[
              styles.container, 
              containerStyles
          ]} 
          behavior="padding">
          {children}
        </KeyboardAvoidingView>
    </ScrollView>
  )
};


  export default Background;