
import { injectable, inject } from "inversify";
import { PermissionsAndroid, Platform } from "react-native";

@injectable()
export class FileService {
    
    async checkPermission(downloadFile: Function) {
        if (Platform.OS === 'android') {
            try {
                const granted = await PermissionsAndroid.request(
                PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
                {
                    title: 'Permisos de almacenamiento requeridos',
                    message: 'La aplicacion necesita acceder a tu almacenadimiento para descargar el archivo',
                    buttonPositive: 'Aceptar',
                    buttonNegative: 'Rechazar'
                }
                );
                if (granted === PermissionsAndroid.RESULTS.GRANTED) {
                // Start downloading
                downloadFile();
                console.log('Storage Permission Granted.');
                } else {
                // If permission denied then show alert
                //Alert.alert('Error','Storage Permission Not Granted');
                }
            } catch (err) {
                // To handle permission related exception
                console.log("++++"+err);
            }
        } else {
            downloadFile();
        }
    }
}