import React from "react";
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import LoginScreen from '../features/login/screens/LoginScreen';

const Stack = createNativeStackNavigator(); 

const AuthStackNavigator = ()=> {
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
        <Stack.Screen
            name="Login"
            component={LoginScreen}
        />
    </Stack.Navigator>
  )
};

export default AuthStackNavigator;