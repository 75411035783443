
import * as React from 'react';
import { View, StyleSheet } from 'react-native'; 
import AttendancePanel from './AttendancePanel';
import { useEffect, useState } from 'react';
import Paragraph from '../../../components/Paragraph';

const SubjectAttendancesPanel = ({subject, ...props}: any)=> {
    const [attendancePercent, setAttendancePercent] = useState<string | null>(null);

    useEffect(()=> {
        console.log(subject);
        if(subject && subject.totalAttendance && subject.maxAttendance && subject.maxAttendance > 0) {
            let attendancePercetVal = subject.totalAttendance * 100;
            attendancePercetVal = attendancePercetVal / subject.maxAttendance;
            attendancePercetVal = Math.round(attendancePercetVal);
            setAttendancePercent(`${attendancePercetVal}%`);
            return;
        }
        setAttendancePercent(null);
    }, [subject]);

    return (
        <View style={styles.main} {...props}>
            <View style={styles.container}>
                <AttendancePanel 
                    description='Parcial 1'
                    attendanceVal={subject.firstPartialAttendance}>
                </AttendancePanel>
                <AttendancePanel 
                    description='Parcial 2'
                    attendanceVal={subject.secondPartialAttendance}>
                </AttendancePanel>
                <AttendancePanel 
                    description='Parcial 3'
                    attendanceVal={subject.thirdPartialAttendance}>
                </AttendancePanel>
                <AttendancePanel
                    description='Final'
                    attendanceVal={`${subject.totalAttendance} de ${subject.maxAttendance}`}>
                </AttendancePanel>
            </View>
        </View>
        );
};

const styles = StyleSheet.create({
    main : {
        width: '100%'
    },
    container: {
        flexDirection:'row', alignItems:'center'
    },
    grade: {
        flex: 1,
        width: '25%'
    }
});

export default SubjectAttendancesPanel;