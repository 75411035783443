import { DefaultTheme } from 'react-native-paper';

export const DarkTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: '#FFFFFF',
      textSecondary: '#FFFFFF',
      primary: '#f44236',
      secondary: '#438afe',
      error: '#f13a59',
      white: '#FFFFFF',
      background: '#000000',
      gray: '#969696',
      lightgray: '#d3d3d3',
      sideMenuBackground: '#8B0000'
    },
  }