export class InplaceQuickSort<T> {
    static inplace_quicksort<T>(arr: T[], low: number, high: number, compareFunc: (a: T, b: T) => number): void {
      if (low < high) {
        let pivotIndex = InplaceQuickSort.partition(arr, low, high, compareFunc);
        InplaceQuickSort.inplace_quicksort(arr, low, pivotIndex - 1, compareFunc);
        InplaceQuickSort.inplace_quicksort(arr, pivotIndex + 1, high, compareFunc);
      }
    }
  
    static partition<T>(arr: T[], low: number, high: number, compareFunc: (a: T, b: T) => number): number {
      let pivot = arr[high];
      let i = low - 1;
  
      for (let j = low; j < high; j++) {
        if (compareFunc(arr[j], pivot) < 0) {
          i++;
          InplaceQuickSort.swap(arr, i, j);
        }
      }
  
      InplaceQuickSort.swap(arr, i + 1, high);
      return i + 1;
    }
  
    static swap<T>(arr: T[], i: number, j: number): void {
      let temp = arr[i];
      arr[i] = arr[j];
      arr[j] = temp;
    }

    static sort<T>(arr: T[], compareFunc: (a: T, b: T) => number): void{
        InplaceQuickSort.inplace_quicksort(arr, 0, arr.length - 1, compareFunc);
    }
  }