import { StyleSheet } from "react-native";
import Button from "./Button";
import Modal from "./Modal";
import Paragraph from "./Paragraph";
import { useTheme } from "../context/ThemeContext";

const ConfirmDialog = ({ visible, children, onConfirm, onCancel, onDismiss, ...props }: any) => {
    const { theme } = useTheme();
    
    const styles = StyleSheet.create({
        confirmButton: {
            backgroundColor: theme.colors.secondary
        },
        cancelButton: {
            backgroundColor: theme.colors.background,
        },
        labelCancelButton: {
            color: theme.colors.secondary
        }
    });

    return (
        <Modal 
            visible={visible} onDismiss={onDismiss} 
            {...props}>
            {children}
            <Button onPress={onConfirm} style={styles.confirmButton}>
                Aceptar
            </Button>
            <Button onPress={onCancel} style={styles.cancelButton} labelStyle={styles.labelCancelButton}>
                Cancelar
            </Button>
        </Modal>
    )
};


export default ConfirmDialog;