import { StyleSheet } from 'react-native';

const getContainerWidth = (width: number) => {
    if(width < 960) {
      return '100%';
    }
    return '100%';
}


const getContainerMaxWidth = (width: number) => {
    if(width < 960) {
      return '100%';
    }
    return '100%';
}

const gradesScreenStyle = (width: number, theme: any) => StyleSheet.create({
    title: {
        marginTop: 10,
    },
    background: {
        width: '100%',
        maxHeight: '95%',
        backgroundColor: theme.colors.background,
        marginBottom: 10
    },
    scrollContainer: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.colors.background,
    },
    list: {
        width: '100%',
        maxWidth: '100%'
    },
    downloadReportGradeContainer: {
        width: '100%',
    },
    downloadPanel: {
        width: '100%',
        borderColor: theme.colors.secondary,
        borderStyle: 'solid',
        borderWidth: 1,
        marginTop: 10,
        borderRadius: 4
    },
    textButtonReportGrade: {
        alignSelf: 'center',
        alignItems: 'center',
        fontSize: 24,
        color: theme.colors.secondary
    },
    buttonReportGrade: {
        width: '100%',
        maxWidth: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        backgroundColor: theme.colors.background,
        padding: 10,
    },
    subjectContainer: {
        flex: 1,
        flexDirection:'row', 
        alignItems:'center', 
        flexWrap: "wrap", 
        columnGap: 5, 
        rowGap: 5
    }
});

export default gradesScreenStyle;