import * as React from 'react';
import { Container } from 'inversify';
import { Provider } from 'inversify-react';
import { LocalStorageService } from '../services/LocalStorageService';
import { TokenService } from '../services/TokenService';
import { AuthService } from '../services/AuthService';
import { StudentService } from '../services/StudetService';
import { AuthInterceptor } from '../interceptors/AuthInterceptor';
import { FileService } from '../services/FileService';
type DIProviderProps = {children: React.ReactNode}

const DIContext = ({children}: DIProviderProps) => {
    return (
        <Provider container={() => {
            const container = new Container();
            container.bind(LocalStorageService).toSelf();
            container.bind(TokenService).toSelf();
            container.bind(AuthService).toSelf();
            container.bind(StudentService).toSelf();
            container.bind(AuthInterceptor).toSelf();
            container.bind(FileService).toSelf();
            return container;
        }}>
            {children}
        </Provider>
    );
};

export {DIContext}