
import * as React from 'react';
import { useState } from 'react';
import {
  Platform,
    StyleSheet,
    useWindowDimensions
  } from 'react-native';
  
import Button from '../../../components/Button'
import TextInput from '../../../components/TextInput';
import Background from '../../../components/Background';
import isCurpValidFormat from '../../../utils/CurpValidator';
import { useInjection } from 'inversify-react';
import { AuthService } from '../../../services/AuthService';
import { horizontalScale, verticalScale } from '../../../helpers/Metrics';
import { Paragraph } from 'react-native-paper';
import { useAssets } from 'expo-asset';
import { Image, View } from 'react-native';
import { useUserLoged } from '../../../context/UserLogedContext';
import loginScreenStyle from '../styles/LoginScreenStyle';
import { StudentService } from '../../../services/StudetService';
import AlertDialog from '../../../components/AlertDialog';


const LoginScreen = ({navigation}: any)=> {
  const [curp, setCurp] = useState({ value: '', error: '' });
  const [controlNumber, setControlNumber] = useState({ value: '', error: '' });
  const [schoolCode, setSchoolCode] = useState({ value: '', error: '' });
  const [ userNotFound, setUserNotFound ] = useState(false);
  const loginService = useInjection(AuthService);
  const studentService = useInjection(StudentService);
  const { userLoged, setUserLoged }  = useUserLoged();
  const {width} = useWindowDimensions();
  const [assets, error] = useAssets([require('../../../assets/app_icon/1x/Logo_StudyApp-red.png'), require('../../../assets/study_logos/login-logo-title.png')]);

  const styles = loginScreenStyle(width)

  const controlNumberValidator = (controlNumber: string) => {
      if (!controlNumber) return "Numero de control es requerido.";
      if (controlNumber.length < 5) return 'Numero de control  debe tener almenos 5 caracteres.';
      return '';
  }
  
  const schoolCodeValidator = (schoolCode: string) => {
    if (!schoolCode) return "Clave de la escuela es requerido.";
    if (schoolCode.length < 5) return 'Clave de la escuela  debe tener almenos 10 caracteres.';
    return '';
}
  const curpValidator = (curp:string) => {
      if (!curp) return "CURP es requerido.";
      if (!isCurpValidFormat(curp)) return 'Formato de CURP invalido.'
      return ''
  }

  const closeUserNotFoundModal = () => {
    setUserNotFound(false);
  }

  const onLoginPressed = () => {
    const curpError = curpValidator(curp.value);
    const controlNumberError = controlNumberValidator(controlNumber.value);
    const schoolCodeError = schoolCodeValidator(schoolCode.value);
    if (curpError || controlNumberError || schoolCodeError) {
      setCurp({ ...curp, error: curpError });
      setControlNumber({ ...controlNumber, error: controlNumberError });
      setSchoolCode({...schoolCode, error: schoolCodeError});
      return
    }
    studentService.getStudent(schoolCode.value, curp.value, controlNumber.value)
      .then((student)=> {
        if(student) {
          loginService.signIn(schoolCode.value, curp.value, controlNumber.value)
          .then(()=> {
            setUserLoged(true);
          }).catch((error) => {
            console.log(error);

          });
        } else {
          setUserNotFound(true);
        }
      });
  }
  return (
    <Background containerStyles={styles.container}>
      {assets && 
        <View style={styles.imageContainer}>
          <Image source={assets[0]} style={styles.image} />
        </View>
      } 
      <TextInput
        label="Escriba la clave de su escuela"
        mode="flat"
        returnKeyType="next"
        value={schoolCode.value}
        onChangeText={(text: string) => setSchoolCode({ value: text.toUpperCase(), error: '' })}
        error={!!schoolCode.error}
        errorText={schoolCode.error}
        autoCapitalize="characters"
        keyboardType="alphanumeric"
      />
      <TextInput
        label="Escriba su CURP"
        mode="flat"
        returnKeyType="next"
        value={curp.value}
        onChangeText={(text: string) => setCurp({ value: text.toUpperCase(), error: '' })}
        error={!!curp.error}
        errorText={curp.error}
        autoCapitalize="characters"
        keyboardType="alphanumeric"
      />
      <TextInput
        label="Escriba su número de control"
        mode="flat"
        returnKeyType="done"
        value={controlNumber.value}
        onChangeText={(text: string) => setControlNumber({ value: text, error: '' })}
        error={!!controlNumber.error}
        errorText={controlNumber.error}
        keyboardType="number-pad"
      />
      <Button mode="contained" onPress={onLoginPressed}>
        VER CALIFICACIONES
      </Button>
      <AlertDialog visible={userNotFound} onConfirm={closeUserNotFoundModal} onDismiss={closeUserNotFoundModal}>
        <Paragraph>No se encontro estudiante con la información ingresada, verifique la información ingresada</Paragraph>
      </AlertDialog>
    </Background>
  )
};


export default LoginScreen;